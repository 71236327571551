<template>
    <div class="is-centered is-full-touch">
        <enso-form ref="form"
                   @ready="init"
                   @submit="submit"
                   disable-state
                   :class="['box', 'form-box', 'form-create', 'has-background-light']">
            <template v-slot:pin_id="props">
                <div class="field">
                    <div class="label">
                        {{ i18n(props.field.label) }}
                    </div>
                    <pin-select class="control" @input="inputPins"/>
                    <p class="help is-danger"
                       v-if="props.errors.errors.hasOwnProperty('pin_id')">
                        {{ i18n(props.errors.errors.pind_id[0]) }}
                    </p>
                </div>
            </template>
            <template v-slot:lat>
                <div>
                    <b-gmap-autocomplete @place_changed="setPlace"/>
                    <GmapMap
                        v-if="lat && lng"
                        :center="{lat, lng}"
                        :zoom="14"
                        style="width: 100%; height: 400px">
                        <GmapMarker
                            :position="{lat, lng}"
                            :draggable="true"
                            @dragend="updateCoordinates($event.latLng)"/>
                    </GmapMap>
                </div>
            </template>
            <template v-slot:image="props">
                <div class="columns custom-section-wrapper is-multiline">
                    <div class="column is-3 section-description">
                        <div class="field">
                            <div class="label">
                                {{ i18n(props.field.label) }}
                            </div>
                        </div>
                        <b-field>
                            <b-upload v-model="image" drag-drop expanded @input="setImage" accept="image/*">
                                <section class="section">
                                    <div class="content has-text-centered">
                                        <p>
                                            <b-icon icon="upload" size="is-large"/>
                                        </p>
                                        <p>Drop your files here or click to upload</p>
                                    </div>
                                </section>
                            </b-upload>
                        </b-field>
                    </div>
                    <div class="column is-3">
                        <img
                            v-if="image"
                            :src="$displayImage(image)">
                    </div>
                </div>
            </template>
        </enso-form>
    </div>
</template>

<script>
import LocationMixin from '@mixins/location/index';
import { EnsoForm } from '@enso-ui/forms/bulma';
import PinSelect from '@components/pins/PinSelect.vue';
import BGmapAutocomplete from '@components/ui/BGmapAutocomplete.vue';

export default {
    name: 'Create',
    components: {
        EnsoForm,
        PinSelect,
        BGmapAutocomplete,
    },
    mixins: [LocationMixin],
    inject: ['i18n', 'route', 'toastr', 'errorHandler'],
    data: () => ({
        image: null,
        pinId: null,
        ready: false,
    }),
    computed: {
        dirty() {
            return this.ready
                ? this.form.dirty
                : {};
        },
        form() {
            return this.ready
                ? this.$refs.form.$refs.form
                : {};
        },
    },
    methods: {
        init() {
            this.ready = true;
            this.pinId = this.form.field('pin_id').value;
        },
        inputPins(pin) {
            if (pin) {
                this.pinId = pin.id;
                this.form.field('pin_id').value = pin.id;
            }
        },
        setImage() {
            this.form.field('image').value = this.image;
        },
        submit(val) {
            if (this.image) {
                const formData = new FormData();
                formData.append('image', this.image);
                axios.post(this.route('locations.image.store', val.location.id), formData)
                    .catch(this.errorHandler);
            }
        },
    },
};
</script>
